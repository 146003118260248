import { history } from '../redux';

const online = navigator.onLine ? true : false;

const handleErrorStatus = (response, e422) => {
  const { status } = response;

  switch(status) {
    case 400:
      if (response.data.errors) {
        const errorMsg = response.data.errors.map(function(item) {
          switch(item.sqlState) {
            case 'duplicateUsers':
              return 'DuplicatedEmail';
            case 'duplicateContact':
              return 'DuplicatedTelephone';
            case 'duplicatePlan':
            case 'duplicateDepartment':
            case 'duplicateTag':
              return 'DuplicatedName';
            case 'foreignKeyViolation':
              return 'ForeignKeyViolation.' + item.parameter;
            default:
              return 'RandomError';
          }
        });
        return errorMsg;
      }
      return 'AuthError';
    case 401:
      history.push('/401');
      window.location.reload();
      return {};
    case 403:
      return 'Unauthorized';
    case 404:
      history.push('/404');
      window.location.reload();
      return {};
    case 422:
      const { errors } = response.data;
      if (errors && e422) {
        return errors;
      }
      return 'InvalidData';
    case 500:
      //'Internal Server Error';
      return 'RandomError';
    default:
      return {};
  }
};

const errorHandler = (error, e422 = true) => {
  const { response } = error;

  if (online) {
    if (response) {
      return handleErrorStatus(response, e422);
    }

    //BackendOff
    if (error.message === 'Network Error') {
      localStorage.removeItem("authUser");
      history.push("/login");
      window.location.reload();
      return {};
    }

    return 'RandomError';
  }
  return 'Offline';
};

export default errorHandler;
