import jwtDecode from 'jwt-decode';
import {
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_RESELLER
} from '../constants/constants';
import avatarW2Z from "../assets/images/users/w2z.jpg";
import avatarHemodiag from "../assets/images/users/hemodiag.jpg";
import avatarM3logsol from "../assets/images/users/m3logsol.jpg";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }

    try {
        const decoded = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            localStorage.removeItem("authUser"); // remove from localstorage
            return false;
        } else {
            return true;
        }
    } catch (e) {
        console.warn('access token expired');
        localStorage.removeItem("authUser"); // remove from localstorage
        return false;
    }
}


/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
    localStorage.setItem('authUser', JSON.stringify(user));
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    return user ? (typeof (user) == 'object' ? user : JSON.parse(user)) : null;
}

const getHomeByLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    let userLogged = user ? (typeof (user) == 'object' ? user : JSON.parse(user)) : null;

    switch (userLogged.data.roles[0].name.toString()) {
        case ROLE_SUPER_ADMIN:
            return "/wzap-administrator";
        case ROLE_ADMIN:
            return "/panel";
        case ROLE_RESELLER:
            return "/dashboard";
        default:
            return "/dashboard";
    }
}

const getAvatarByLoggedUser = () => {
    const userLogged = getLoggedInUser();
    const { account } = userLogged?.data;

    if (account) {
        switch (account.accountSid) {
            //Hemodiag
            case 'AC91f2ae843d887bb9077750e2c9d19f76':
                return avatarHemodiag;
            //M3logSol
            case 'AC3388e23d8ca203a25b13f53528867752':
                return avatarM3logsol;
            default:
                return avatarW2Z;
        }
    }
    return avatarW2Z;
}

const getInstanceId = () => {
    const instanceId = localStorage.getItem('instanceId');
    return instanceId ? (typeof (instanceId) == 'object' ? instanceId : JSON.parse(instanceId)) : null;
}

export { isUserAuthenticated, setLoggedInUser, getLoggedInUser, getHomeByLoggedInUser, getAvatarByLoggedUser, getInstanceId };